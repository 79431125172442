import React, { useState } from 'react';
import axios from 'axios';

const ChatWithGPT = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendClick = async () => {
    try {
      const result = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: input }],
      }, {
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json',
        },
      });

      const gptResponse = result.data.choices[0].message.content;
      setResponse(gptResponse);
      speak(gptResponse);
    } catch (error) {
      console.error('Error fetching data from OpenAI:', error);
    }
  };

  const speak = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div>
      <h1>Chat with GPT Speeking</h1>
      <textarea value={input} onChange={handleInputChange} placeholder="Ask something..." />
      <button onClick={handleSendClick}>Send</button>
      <p>Response: {response}</p>
    </div>
  );
};

export default ChatWithGPT;
